import { render, staticRenderFns } from "./dropdown_contents_labels_view.vue?vue&type=template&id=00df2b8c"
import script from "./dropdown_contents_labels_view.vue?vue&type=script&lang=js"
export * from "./dropdown_contents_labels_view.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports